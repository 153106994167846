import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/About',
    name: 'About',
    component: () => import('../views/About.vue'),
    children: [
      {
        path: '/About/TeacherGuide',
        name: 'TeacherGuide',
        component: () => import('../views/TeacherGuide.vue')
      },
      {
        path: '/About/Credits',
        name: 'Credits',
        component: () => import('../views/Credits.vue')
      }
    ]
  },
  {
    path: '/Solutions',
    name: 'Solutions',
    component: () => import('../views/Solutions.vue')
  },
  {
    path: '/Glossary',
    name: 'Glossary',
    component: () => import('../views/Glossary.vue')
  },
  {
    path: '/Credits',
    name: 'Credits',
    component: () => import('../views/Credits.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})



export default router
