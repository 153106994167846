import Vue from 'vue'
import App from './App.vue'
import router from './router'
import css from './index.css'
import store from './store'
// import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';
// Vue.component('loading-overlay', Loading)

Vue.config.productionTip = false

new Vue({
  router,
  css,
  store,
  render: h => h(App)
}).$mount('#app')
