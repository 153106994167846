<template>
    <div>
        <div class="hover-container">
            <img :src="['../assets/img/c'+ solutionNum +'.png']" :alt="['Solution #'+solutionNum+' - '+solutionName]" class="hover-img">
            <a href="#">
                <div class="overlay bg-drawdown75 md:bg-drawdown50 md:hover:bg-drawdown75 hover:bg-drawdown">
                    <div class="hover-text font-body font-thin text-white tracking-wide text-sml md:text-smd italic">
                        Solution #{{solutionNum}}
                        <div class="md:pt-2 pt-1 md:mx-2 xs:mx-1 mx-2 font-display tracking-normal text-smbs sm:text-base md:text-mdsm leading-tight md:leading-9 not-italic">
                            {{solutionName}}
                        </div>
                    </div>
                </div>
            </a>
        </div>              
    </div>
</template>

<script>
export default {
  name: 'HoverBox',
  props: {
    solutionNum: String,
    solutionName: String
  }
}
</script>